/* eslint-disable camelcase */
/* eslint-disable object-shorthand */
/* eslint-disable spaced-comment */
import { createSlice } from '@reduxjs/toolkit';
// QS
import queryString from 'query-string';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  rooms: [],
  roomsByFrigo: [],
  room: null,
  selectedRoomId: null,
  // -----------------
  frigoRooms: [],
  roomSettings: [],
  allRoomSettings: [],
  notifications: [],
  notification: null,
};

const slice = createSlice({
  name: 'room',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // clear state on page change
    clearRoomsByFrigo(state) {
      state.isLoading = false;
      state.error = null;
      state.rooms = [];
      state.roomsByFrigo = [];
      state.room = null;
      state.selectedRoomId = null;
      // -----------------
      state.frigoRooms = [];
      state.roomSettings = [];
      state.allRoomSettings = [];
      state.notifications = [];
      state.notification = null;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET ROOMS
    getRoomsSuccess(state, action) {
      state.isLoading = false;
      state.rooms = action.payload;
    },

    // GET ROOMS
    getNotificationSuccess(state, action) {
      state.isLoading = false;
      state.notifications = action.payload;
    },

    // GET ROOM SETTINGS
    getRoomSettingsSuccess(state, action) {
      state.isLoading = false;
      state.roomSettings = action.payload;
    },

    // GET ROOM SETTINGS
    getAllRoomSettingsSuccess(state, action) {
      state.isLoading = false;
      state.allRoomSettings = action.payload;
    },

    getRoomsByFrigoSuccess(state, action) {
      state.isLoading = false;
      state.roomsByFrigo = action.payload;
    },

    getFrigoRoomsSuccess(state, action) {
      state.isLoading = false;
      state.frigoRooms = action.payload;
    },

    // GET SINGLE ROOM
    getRoomSuccess(state, action) {
      state.isLoading = false;
      state.room = action.payload;
    },

    // CREATE ROOM
    createRoomSuccess(state, action) {
      const newRoom = action.payload;
      state.isLoading = false;
      state.rooms = [...state.rooms, newRoom];
    },

    // DEVICE SETTINGS
    createRoomSettingsSuccess(state, action) {
      const newRoomSettings = action.payload;
      state.isLoading = false;
      state.roomSettings = [...state.roomSettings, newRoomSettings];
    },

    // UPDATE ROOM
    updateRoomSuccess(state, action) {
      const room = action.payload;
      const updateRoom = state.rooms.map((_room) => {
        if (_room.id === room.id) {
          return room;
        }
        return _room;
      });

      state.isLoading = false;
      state.rooms = updateRoom;
    },

    updateRoomSettingsSuccess(state, action) {
      const roomSettings = action.payload;

      const updateRoomSettings = state.roomSettings.map((_roomSettings) => {
        if (_roomSettings.id === roomSettings.id) {
          return roomSettings;
        }
        return _roomSettings;
      });

      state.isLoading = false;

      state.roomSettings = updateRoomSettings;
    },

    // DELETE ROOM
    deleteRoomSuccess(state, action) {
      const { roomId } = action.payload;
      const deleteRoom = state.roomsByFrigo.filter((room) => room.id !== roomId);
      // state.rooms = deleteRoom;
      state.isLoading = false;
      state.roomsByFrigo = deleteRoom;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------
const POST_ROOM_SETTINGS_API = `/services/notification/api/room-settings`;
const GET_ROOM_SETTINGS_API = `/services/notification/api/room-settings/room`;
const PATCH_ROOM_SETTINGS_API = `/services/notification/api/room-settings`;
// ----------------------------------------------------------------------
// * -------------------------------------------------------------------------------
export function getNotifications(ownerId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/services/notification/api/notifications?ownerId.eq=${ownerId}&page=0&size=20`);

      dispatch(slice.actions.getNotificationSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// * -------------------------------------------------------------------------------

export function getRooms(ownerId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/services/ownership/api/rooms?ownerId.eq=${ownerId}&page=0&size=20`);
      dispatch(slice.actions.getRoomsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ---------------------------------------------------------------------
export function getRoomsByFrigo(roomIds) {
  const roomsIds = queryString.stringify({ 'id.in': roomIds }, { arrayFormat: 'comma' });

  if (
    roomsIds === '' ||
    roomsIds === undefined ||
    roomsIds === 'id.in=undefined' ||
    roomsIds === 'id.in=null' ||
    roomsIds === 'id.in=0' ||
    roomsIds === 'id.in=NaN' ||
    roomsIds === 'id.in=0,0' ||
    roomsIds === 'id.in='
  ) {
    return () => [];
  }

  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/services/ownership/api/rooms?${roomsIds}`);

      dispatch(slice.actions.getRoomsByFrigoSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ---------------------------------------------------------------------
export function getFrigoRooms(frigoId) {
  if (frigoId === '' || frigoId === undefined) {
    return () => [];
  }

  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/services/ownership/api/rooms?frigoId.eq=${frigoId}`);

      dispatch(slice.actions.getFrigoRoomsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
export function getRoom(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/services/ownership/api/rooms/${id}`);

      dispatch(slice.actions.getRoomSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------
export function getRoomByCode(code) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/services/ownership/api/rooms?code.eq=${code}`);

      dispatch(slice.actions.getRoomSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------
export function createRoom(newRoom) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      // const response = await axios.post('/services/ownership/api/rooms', newRoom);

      const response2 = await axios('/services/ownership/api/rooms', {
        method: 'post',
        data: newRoom,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });

      const roomSettings = await axios(`${POST_ROOM_SETTINGS_API}`, {
        method: 'post',
        data: {
          roomId: response2.data.id,
          ownerId: response2.data.ownerId,
          isActivated: false,
          isTemperatureActivated: false,
          temperatureMin: 0,
          temperatureMax: 0,
          isHumidityActivated: false,
          humidityMin: 0,
          humidityMax: 0,
          product: 'string',
          variety: 'string',
          email: [],
        },
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });

      dispatch(slice.actions.createRoomSuccess(response2.data));
      dispatch(slice.actions.createRoomSettingsSuccess(roomSettings.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// TODO | ----------------------------------------------------------------------
export function createRoomSettings(settingsId, settings) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.patch(`${PATCH_ROOM_SETTINGS_API}/${settingsId}`, settings);

      dispatch(slice.actions.updateRoomSettingsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getRoomSettings(roomId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${PATCH_ROOM_SETTINGS_API}/room/${roomId}`);

      dispatch(slice.actions.getRoomSettingsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getRoomSettingsByOwner(ownerId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/services/notification/api/room-settings/owner/${ownerId}`);

      dispatch(slice.actions.getAllRoomSettingsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function updateRoom(data, roomId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      // const response = await axios({
      //   method: 'patch',
      //   url: `/services/ownership/api/rooms/${roomId}`,
      //   data,
      // });

      const response = await axios.patch(`/services/ownership/api/rooms/${roomId}`, data);

      dispatch(slice.actions.updateRoomSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteRoom(roomId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.delete(`/services/ownership/api/rooms/${roomId}`);
      dispatch(slice.actions.deleteRoomSuccess({ roomId }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      console.log(error);
    }
  };
}

// ----------------------------------------------------------------------
