/* eslint-disable camelcase */
/* eslint-disable object-shorthand */
/* eslint-disable spaced-comment */
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  currentOwner: null,
  owners: [],
};

const slice = createSlice({
  name: 'owner',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET OWNERS
    getOwnersSuccess(state, action) {
      state.isLoading = false;
      state.owners = action.payload;
    },

    // GET OWNER
    getOwnerSuccess(state, action) {
      state.isLoading = false;
      state.currentOwner = action.payload;
    },

    // ADD NEW OWNER
    createOwnerSuccess(state, action) {
      const newOwner = action.payload;
      state.isLoading = false;
      state.owners = [...state.owners, newOwner];
    },

    // UPDATE OWNER SUCCESS
    updateOwnerSuccess(state, action) {
      const owner = action.payload;
      const updateOwner = state.owners.map((_owner) => {
        if (_owner.id === owner.id) { 
          return owner; 
        }
        return _owner;
      });

      state.isLoading = false;
      state.owners = updateOwner;
      state.currentOwner = owner;
    },

  },
});

// Reducer
export default slice.reducer;

// ---------------------------------------------------------------------
const OWNER_API = `/api/owners`;
// ---------------------------------------------------------------------

export function getOwners() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(OWNER_API);
      dispatch(slice.actions.getOwnersSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ---------------------------------------------------------------------

export function getOwner(ownerId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${OWNER_API}/${ownerId}`);

      dispatch(slice.actions.getOwnerSuccess(response.data));

    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// -----------------------------------------------------------------------

export function addOwner(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {

      const response = await axios(OWNER_API, {
        method: "post",
        data,
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json"
        }
      });

      dispatch(slice.actions.createOwnerSuccess(response.data));

    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// -----------------------------------------------------------------------

export function updateOwner(data, ownerId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {

      // const response = await axios({
      //   method: 'patch',
      //   url: `${OWNER_API}/${ownerId}`,
      //   data,
      // });

      const response = await axios.patch(`${OWNER_API}/${ownerId}`, data);

      dispatch(slice.actions.updateOwnerSuccess(response.data));

    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}