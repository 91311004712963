import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

LogoLoading.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function LogoLoading({ disabledLink = false, sx }) {

  const LogoLoading = (
    <Box sx={{ width: 37, height: 37, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 120">
        <defs>
          <linearGradient
            id="a"
            x1={60.86}
            y1={86.69}
            x2={60.86}
            y2={28.71}
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0} stopColor="#a1ff0c" />
            <stop offset={0.3} stopColor="#9df90b" />
            <stop offset={0.67} stopColor="#94ea0a" />
            <stop offset={1} stopColor="#88d70a" />
          </linearGradient>
          <linearGradient
            id="b"
            x1={36.66}
            y1={86.69}
            x2={36.66}
            y2={28.71}
            xlinkHref="#a"
          />
        </defs>
        <path
          style={{
            fill: "url(#a)",
          }}
          d="M107.58 46.56 80.59 93.02h-32.5l-8.48-14.59H72.6l18.93-32.59-18.9-32.66H35.01l-5.24 9.03H14.13L27.03 0h53.62l26.93 46.56z"
        />
        <path
          style={{
            fill: "url(#b)",
          }}
          d="m19.81 39.33-3.77 6.47 3.11 5.38h30.58l-6.09 13.16H26.83l5.88 10.12L43.5 93.02H27.03L0 46.52l11.83-20.34h53.83l7.66 13.15H19.81z"
        />
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{LogoLoading}</>;
  }

  return <RouterLink to="/">{LogoLoading}</RouterLink>;
}
