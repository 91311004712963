// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, '/frigo'),
    room: (code) => path(ROOTS_DASHBOARD, `/room/${code}`),
    analytics: path(ROOTS_DASHBOARD, '/analytics'),
    banking: path(ROOTS_DASHBOARD, '/maintenance'),
    booking: path(ROOTS_DASHBOARD, '/reporting'),
    settings: path(ROOTS_DASHBOARD, '/settings'),
    users: path(ROOTS_DASHBOARD, '/users'),
    monitoring: path(ROOTS_DASHBOARD, '/monitoring'),
  },
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    new: path(ROOTS_DASHBOARD, '/user/new'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    account: path(ROOTS_DASHBOARD, '/user/account'),
    edit: (name) => path(ROOTS_DASHBOARD, `/user/${name}/edit`),
  },
  // eCommerce: {
  //   root: path(ROOTS_DASHBOARD, '/e-commerce'),
  //   shop: path(ROOTS_DASHBOARD, '/frigos/list'),
  //   list: path(ROOTS_DASHBOARD, '/frigos/energy'),
  //   checkout: path(ROOTS_DASHBOARD, '/e-commerce/checkout'),
  //   new: path(ROOTS_DASHBOARD, '/e-commerce/product/new'),
  //   view: (name) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}`),
  //   edit: (name) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}/edit`),
  //   demoEdit: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-blazer-low-77-vintage/edit'),
  //   demoView: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-air-force-1-ndestrukt'),
  // },
  // -----------------------------------------------------------------------------
  frigo: {
    dashboard: path(ROOTS_DASHBOARD, '/frigo'),
    list: path(ROOTS_DASHBOARD, '/frigos/list'),
    edit: (idFrigo) => path(ROOTS_DASHBOARD, `/frigos/${idFrigo}/editer`), // Edit Frigo
    details: (id) => path(ROOTS_DASHBOARD, `/frigos/list/${id}`), // Detail Frigo
    new: path(ROOTS_DASHBOARD, '/frigos/new'), // Create New Frigo
  },
  room: {
    list: path(ROOTS_DASHBOARD, '/frigos/chambres'),
    list2: path(ROOTS_DASHBOARD, '/frigos/chambres2'),
    edit: (id) => path(ROOTS_DASHBOARD, `/room/${id}/editer`), // Edit Room
    details: (id) => path(ROOTS_DASHBOARD, `/room/${id}`), // Room Detail
    new: (frigoId) => path(ROOTS_DASHBOARD, `/${frigoId}/room/new`), // Create New Room
  },
  energy: {
    list: path(ROOTS_DASHBOARD, '/frigos/energy'),
    // details: (id) => path(ROOTS_DASHBOARD, `/energy/list/${id}`), // Detail Energy
  },
  device: {
    new: path(ROOTS_DASHBOARD, '/device/new'),
    edit: (id) => path(ROOTS_DASHBOARD, `/device/${id}/editer`), // Edit Room
  },
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
