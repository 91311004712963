import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {

  const logo = (
    <Box sx={{ width: 150, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 478 110" >

        <defs>
          <linearGradient
            id="a"
            x1={150.9}
            y1={86.69}
            x2={150.9}
            y2={28.71}
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0} stopColor="#a1ff0c" />
            <stop offset={0.3} stopColor="#9df90b" />
            <stop offset={0.67} stopColor="#94ea0a" />
            <stop offset={1} stopColor="#88d70a" />
          </linearGradient>
          <linearGradient
            id="b"
            x1={187.26}
            y1={86.69}
            x2={187.26}
            y2={28.71}
            xlinkHref="#a"
          />
          <linearGradient
            id="c"
            x1={212.65}
            y1={86.69}
            x2={212.65}
            y2={28.71}
            xlinkHref="#a"
          />
          <linearGradient
            id="d"
            x1={246.37}
            y1={86.69}
            x2={246.37}
            y2={28.71}
            xlinkHref="#a"
          />
          <linearGradient
            id="e"
            x1={294.27}
            y1={86.69}
            x2={294.27}
            y2={28.71}
            xlinkHref="#a"
          />
          <linearGradient
            id="f"
            x1={327}
            y1={86.69}
            x2={327}
            y2={28.71}
            xlinkHref="#a"
          />
          <linearGradient
            id="g"
            x1={372.67}
            y1={86.69}
            x2={372.67}
            y2={28.71}
            xlinkHref="#a"
          />
          <linearGradient
            id="h"
            x1={429.41}
            y1={86.69}
            x2={429.41}
            y2={28.71}
            xlinkHref="#a"
          />
          <linearGradient
            id="i"
            x1={60.86}
            y1={86.69}
            x2={60.86}
            y2={28.71}
            xlinkHref="#a"
          />
          <linearGradient
            id="j"
            x1={36.66}
            y1={86.69}
            x2={36.66}
            y2={28.71}
            xlinkHref="#a"
          />
        </defs>
        <path
          d="M133.28 70.98V15.31h35.25v12.78h-21.45v9.94h18.69v12.7h-18.69V71h-13.8Z"
          style={{
            fill: "url(#a)",
          }}
        />
        <path
          d="M174.36 70.98V31.24h12.7v6.23c1.89-3.94 5.91-7.1 11.28-7.1.47 0 1.42.08 1.81.16l-.32 13.72c-1.97-.32-2.92-.39-4.42-.39-5.6 0-8.12 3.94-8.12 9.07v18.06h-12.93Z"
          style={{
            fill: "url(#b)",
          }}
        />
        <path
          d="M205.2 19.72c0-3.94 3.23-7.02 7.49-7.02s7.41 3.08 7.41 7.02-3.15 7.18-7.41 7.18-7.49-3.15-7.49-7.18Zm.79 51.26V31.24h13.33v39.75h-13.33Z"
          style={{
            fill: "url(#c)",
          }}
        />
        <path
          d="m227.52 78.56 10.17-5.76c1.89 2.68 4.65 3.94 8.44 3.94 5.13 0 8.04-2.44 8.04-7.49v-4.1c-2.6 3.63-6.31 5.44-11.28 5.44-10.17 0-17.27-8.12-17.27-20.19s7.1-20.03 17.35-20.03c4.81 0 8.6 1.66 11.2 5.36v-4.5h12.93v35.88c0 13.64-8.12 20.19-20.58 20.19-8.28 0-15.06-2.44-19.01-8.75Zm26.97-27.92c0-5.13-3-8.52-7.81-8.52s-8.04 3.39-8.04 8.52 3.31 8.75 8.04 8.75 7.81-3.55 7.81-8.75Z"
          style={{
            fill: "url(#d)",
          }}
        />
        <path
          d="M272.86 51.11c0-12.46 9.38-20.74 21.45-20.74s21.37 8.28 21.37 20.74-9.15 20.74-21.37 20.74-21.45-8.28-21.45-20.74Zm29.81 0c0-5.36-3.23-8.99-8.36-8.99s-8.44 3.63-8.44 8.99 3.47 8.99 8.44 8.99 8.36-3.63 8.36-8.99Z"
          style={{
            fill: "url(#e)",
          }}
        />
        <path
          d="M319 63.81c0-4.5 3.39-7.73 7.96-7.73s8.04 3.23 8.04 7.73-3.39 7.89-8.04 7.89-7.96-3.31-7.96-7.89Z"
          style={{
            fill: "url(#f)",
          }}
        />
        <path
          d="M403.3 47.72v23.26h-13.01V50.32c0-5.91-1.73-8.28-5.28-8.28s-5.91 3.47-5.91 8.04v20.9h-12.93V50.32c0-5.91-1.73-8.28-5.28-8.28s-5.91 3.47-5.91 8.36v20.58h-12.93V31.24h12.7v5.05c2.45-3.71 6.31-5.91 11.28-5.91s8.75 2.21 10.57 6.62c4.1-5.13 8.83-6.62 13.09-6.62 8.83 0 13.64 5.76 13.64 17.35Z"
          style={{
            fill: "url(#g)",
          }}
        />
        <path
          d="M408.67 51.11c0-12.46 7.1-20.74 17.35-20.74 4.81 0 8.6 1.81 11.2 5.76v-4.89h12.93v39.75h-12.93v-4.81c-2.6 3.79-6.31 5.68-11.28 5.68-10.17 0-17.27-8.28-17.27-20.74Zm28.86 0c0-5.36-3-8.99-7.81-8.99s-8.04 3.63-8.04 8.99 3.31 8.99 8.04 8.99 7.81-3.63 7.81-8.99Z"
          style={{
            fill: "url(#h)",
          }}
        />
        <path
          style={{
            fill: "url(#i)",
          }}
          d="M107.58 46.56 80.59 93.02h-32.5l-8.48-14.59H72.6l18.93-32.59-18.9-32.66H35.01l-5.24 9.03H14.13L27.03 0h53.62l26.93 46.56z"
        />
        <path
          style={{
            fill: "url(#j)",
          }}
          d="m19.81 39.33-3.77 6.47 3.11 5.38h30.58l-6.09 13.16H26.83l5.88 10.12L43.5 93.02H27.03L0 46.52l11.83-20.34h53.83l7.66 13.15H19.81z"
        />
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
