import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import roomReducer from './slices/room';
import frigoReducer from './slices/frigo';
import streamReducer from './slices/stream';
import deviceReducer from './slices/device';
import ownerReducer from './slices/owner';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  // keyPrefix: 'redux-',
  // whitelist: [],
};

const rootReducer = combineReducers({
  room: roomReducer,
  frigo: frigoReducer,
  stream: streamReducer,
  device: deviceReducer,
  owner: ownerReducer,
});

export { rootPersistConfig, rootReducer };
