/* eslint-disable camelcase */
/* eslint-disable object-shorthand */
/* eslint-disable spaced-comment */
import { createSlice } from '@reduxjs/toolkit';
// QS
import queryString from 'query-string';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  streams: [],
  roomStreams: [],
  energyStreams: [],
};

const slice = createSlice({
  name: 'stream',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET STREAMS
    getStreamsSuccess(state, action) {
      state.isLoading = false;
      state.streams = action.payload;
    },

    // GET ROOM STREAMS
    getRoomStreamsSuccess(state, action) {
      state.isLoading = false;
      state.roomStreams = action.payload;
    },

    // GET ENERGY STREAMS
    getEnergyStreamsSuccess(state, action) {
      state.isLoading = false;
      state.energyStreams = action.payload;
    },

    // GET STREAM
    getStreamSuccess(state, action) {
      state.isLoading = false;
      state.stream = action.payload;
    },

    // GET STREAM BY DEVICE
    getStreamByDeviceSuccess(state, action) {
      state.isLoading = false;
      state.streamByDevice = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ---------------------------------------------------------------------
const API_STREAMS = `/services/sensoring/api/streams`;
// ----------------------------------------------------------------------

export function getStreams() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(API_STREAMS);

      dispatch(slice.actions.getStreamsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ---------------------------------------------------------------------

export function getRoomStreams(roomDevices) {
  const devicesIds = queryString.stringify({ 'deviceId.in': roomDevices }, { arrayFormat: 'comma' });

  if (devicesIds === '' || devicesIds === undefined) {
    return () => [];
  }

  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/services/sensoring/api/streams?type.eq=CLIMATE&${devicesIds}&size=500000`);

      dispatch(slice.actions.getRoomStreamsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ---------------------------------------------------------------------

export function getEnergyStreams() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${API_STREAMS}?type.eq=ENERGY`);

      dispatch(slice.actions.getEnergyStreamsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ---------------------------------------------------------------------

export function getStream(streamId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${API_STREAMS}/id/${streamId}`);

      dispatch(slice.actions.getStreamSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ---------------------------------------------------------------------

export function getStreamByDevice(deviceId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${API_STREAMS}?deviceId.eq=${deviceId}`);

      dispatch(slice.actions.getStreamByDeviceSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ---------------------------------------------------------------------
