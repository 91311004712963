import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'verify', element: <VerifyCode /> },
      ],
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'frigo', element: <GeneralApp /> },
        // Room
        {
          path: 'room/:id',
          element: <DetailsChamber />,
        },
        {
          path: 'room/:id/editer',
          element: (
            // <RoleBasedGuard accessibleRoles={user.authorities}>
            <RoomCreateEdit />
            // </RoleBasedGuard>
          ),
        },
        {
          path: ':frigoId/room/new',
          element: (
            // <RoleBasedGuard accessibleRoles={user.authorities}>
            <RoomCreateEdit />
            // </RoleBasedGuard>
          ),
        },
        // Device
        {
          path: 'device/:id/editer',
          element: (
            // <RoleBasedGuard accessibleRoles={user.authorities}>
            <DeviceCreateEdit />
            // </RoleBasedGuard>
          ),
        },
        {
          path: 'device/new',
          element: (
            // <RoleBasedGuard accessibleRoles={user.authorities}>
            <DeviceCreateEdit />
            // </RoleBasedGuard>
          ),
        },
        {
          path: 'devices',
          element: <ListDevices />,
        },
        // General
        { path: 'analytics', element: <DetailsEnergy /> },
        { path: 'maintenance', element: <GeneralBanking /> },
        { path: 'reporting', element: <GeneralBooking /> },
        {
          path: 'settings',
          element: (
            // <RoleBasedGuard accessibleRoles={user.authorities}>
            <Settings />
            // </RoleBasedGuard>
          ),
        },
        { path: 'users', element: <Users /> },
        { path: 'statistics', element: <Statistics /> },
        {
          path: 'frigos',
          children: [
            { element: <Navigate to="/dashboard/frigos/list" replace />, index: true },
            { path: 'list', element: <ListFrigos /> },
            { path: 'chambres', element: <RoomsList /> },
            { path: 'chambres2', element: <RoomsList2 /> },
            { path: 'energy', element: <EnergyByFrigo /> },
            { path: 'list/:id', element: <FrigoDetails /> },
            {
              path: ':idFrigo/editer',
              element: (
                // <RoleBasedGuard accessibleRoles={user.authorities}>
                <FrigoCreateEdit />
                // </RoleBasedGuard>
              ),
            },
            {
              path: 'new',
              element: (
                // <RoleBasedGuard accessibleRoles={user.authorities}>
                <FrigoCreateEdit />
                // </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/profile" replace />, index: true },
            { path: 'profile', element: <FrigoDetails /> },
            { path: 'cards', element: <UserCards /> },
            // { path: 'list', element: <UserList /> },
            { path: 'new', element: <UserCreate /> },
            { path: ':name/edit', element: <UserCreate /> },
            { path: 'account', element: <UserAccount /> },
          ],
        },
        {
          // Device monitoring
          path: 'monitoring',
          element: <Monitoring />,
        },
      ],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: '/',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [{ element: <GeneralApp />, index: true }],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

//! **********************************************************************************************

// ** AUTHENTICATION
const Login = Loadable(lazy(() => import('../views/auth/Login')));
const Register = Loadable(lazy(() => import('../views/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../views/auth/ResetPassword')));
const VerifyCode = Loadable(lazy(() => import('../views/auth/VerifyCode')));

//! **********************************************************************************************

// ** DASHBOARD

// ? GENERAL
const GeneralApp = Loadable(lazy(() => import('../views/dashboard/GeneralApp')));
const GeneralBanking = Loadable(lazy(() => import('../views/dashboard/MaintenanceSection')));
const GeneralBooking = Loadable(lazy(() => import('../views/dashboard/GeneralBooking')));
const Settings = Loadable(lazy(() => import('../views/dashboard/Settings')));
const Users = Loadable(lazy(() => import('../sections/@dashboard/user/profile/FrigoUsers')));

// ? FRIGO
const ListFrigos = Loadable(lazy(() => import('../views/dashboard/frigo/ListFrigos')));
const FrigoDetails = Loadable(lazy(() => import('../views/dashboard/frigo/FrigoDetails')));
const FrigoCreateEdit = Loadable(lazy(() => import('../views/dashboard/frigo/FrigoCreate')));

// ? STATISTICS
const Statistics = Loadable(lazy(() => import('../views/dashboard/Statistics')));

// ? ROOMS
const DetailsChamber = Loadable(lazy(() => import('../views/dashboard/chambre/DetailsChamber')));
const RoomCreateEdit = Loadable(lazy(() => import('../views/dashboard/chambre/ChambreCreate')));
const RoomsList = Loadable(lazy(() => import('../views/dashboard/chambre/RoomsList')));
const RoomsList2 = Loadable(lazy(() => import('../views/dashboard/chambre/RoomsList2')));

// ? DEVICE
// const DetailsDevice = Loadable(lazy(() => import('../views/dashboard/device/DetailsDevice')));
const DeviceCreateEdit = Loadable(lazy(() => import('../views/dashboard/device/DeviceCreate')));
const ListDevices = Loadable(lazy(() => import('../sections/@dashboard/device/ListDevices')));

// ? ENERGY
const EnergyByFrigo = Loadable(lazy(() => import('../views/dashboard/energy/EnergyByFrigo')));
const DetailsEnergy = Loadable(lazy(() => import('../views/dashboard/energy/DetailsEnergy')));

// ? USER
const UserCards = Loadable(lazy(() => import('../views/dashboard/user/UserCards')));
// const UserList = Loadable(lazy(() => import('../views/dashboard/user/UserList')));
const UserAccount = Loadable(lazy(() => import('../views/dashboard/user/UserAccount')));
const UserCreate = Loadable(lazy(() => import('../views/dashboard/user/UserCreate')));

//! **********************************************************************************************

// * MAIN
const ComingSoon = Loadable(lazy(() => import('../views/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../views/Maintenance')));
const NotFound = Loadable(lazy(() => import('../views/Page404')));

//! **********************************************************************************************

// ** Monitoring
const Monitoring = Loadable(lazy(() => import('../views/dashboard/monitoring/GeneralMonitoring')));
