import PropTypes from 'prop-types';
// form
import { Controller, useFormContext } from 'react-hook-form';
// @mui
import { TextField } from '@mui/material';
import { useFormControl } from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import { useMemo } from 'react';
// ----------------------------------------------------------------------

function MyFormHelperText() {
  const { focused } = useFormControl() || {};

  const helperText = useMemo(() => {
    if (focused) {
      return 'This field is being focused ApiKey';
    }
    return 'dfdf';
  }, [focused]);

  return <FormHelperText>{helperText}</FormHelperText>;
}

// ----------------------------------------------------------------------

RHFHelperTextField.propTypes = {
  name: PropTypes.string,
};

export default function RHFHelperTextField({ name, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField {...field} fullWidth error={!!error} InputLabelProps={{ shrink: true }} helperText={error?.message || 'Api Key est un lorem ipsuim text field'} {...other} />
      )}
    />
  );
}
